import { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { RefreshOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import filterIcon from '../../assets/images/filter.svg'
import { useAuth } from '../../contexts/AuthContext'
import { useTourContext } from '../../contexts/TourContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { storeAccountData, storeMarketValueData, storeModelsData, storeSummaryData } from '../../store/dashboard-reducer/dashboard-reducer'
import AllAccounts from './AllAccounts'
import FilterMenu from './FilterMenu'
import MarketValueChart from './MarketValueChart'
import Statistics from './Statistics'
import TopAssignedModels from './TopAssignedModels'

export const AccountListContext = createContext()

export const useAccountListContext = () => useContext(AccountListContext)

const BookOfBusiness = () => {
  const { user, getCurrentRedirectionPath, setUserAclData } = useAuth()
  const dispatch = useDispatch()
  const { showError } = useErrorToast()
  // const { modelsData, summaryData, accountData, marketValueData } = useSelector((state) => state.dashboard)

  const [topAssignedModels, setTopAssignedModels] = useState()
  const [allAssignedModels, setAllAssignedModels] = useState()

  const [accounts, setAccounts] = useState([])
  const [strategies, setStrategies] = useState([])

  const [accountSummaryMetrics, SetAccountSummaryMetrics] = useState({})
  const [AUMData, setAUMData] = useState([])

  const [selectedStrategyFilters, setSelectedStrategyFilters] = useState([])
  const [selectedRangeFilters, setSelectedRangeFilters] = useState([])
  const [selectedFiltersCopy, setSelectedFiltersCopy] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [chartLoading, setChartLoading] = useState(false)
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [AUMLoading, setAUMLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const { setFetchedAllApiResponse } = useTourContext()

  const customHeaders = {
    serviceId: 'book-of-business',
    resourceId: 'book-of-business'
  }

  useEffect(() => {
    if (user) {
      // check if data is present in redux then avoid API call
      // if (modelsData?.length) {
      //   filterTopAssignedModels([...modelsData])
      // } else {
      getTopAssignedModels()
      // }
      // if (accountData?.length) {
      //   setAccounts(accountData)
      //   const storeArray = []
      //   accountData.forEach((element) => {
      //     storeArray.push({
      //       accountName: element.accountName,
      //       accountType: element.accountType,
      //       accountCode: element.accountCode,
      //       strategyId: element.strategyId,
      //       custodianAccountNumber: element?.custodianAccountNumber,
      //       portSpecName: element.portSpecName,
      //       accountId: element.accountId,
      //       priority: element.priority,
      //       totalMarketValue: element.totalMarketValue
      //     })
      //   })
      //   localStorage.setItem('object', JSON.stringify(storeArray))
      //   setFetchedAllApiResponse(true)
      // } else {
      getAccountsList()
      // }
      // if (summaryData && Object.keys(summaryData).length) {
      //   SetAccountSummaryMetrics(summaryData)
      // } else {
      getAccountSummaryMetrics()
      // }
      // if (marketValueData?.length) {
      //   setAUMData(marketValueData)
      // } else {
      getAUMData()
      // }
      getStrategyList()
    }
  }, [user])

  const fetchACLAccess = (props, cache) => {
    setTableLoading(true)
    setAUMLoading(true)
    setChartLoading(true)
    setSummaryLoading(true)
    API.get('baseAclURL', `user-access-control/v1/user/${props}${cache ? '?cache-override=true' : ''}`)
      .then((res) => {
        if (res && res.success && res.data) {
          getCurrentRedirectionPath(res.data)
          setUserAclData(res.data)
          localStorage.setItem('user-acl-data', JSON.stringify(res.data))
          removeAllFilters(true)
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        removeAllFilters(true)
      })
  }

  const refreshDashboardData = () => {
    if (user)
      fetchACLAccess(user?.sub, true)
  }

  const filterTopAssignedModels = (data) => {
    if (data.length > 0) {
      if (data.length > 5) {
        let othersValue = 0
        let otherAccounts = 0
        data.slice(4).forEach(model => {
          othersValue = othersValue + model.totalMarketValue
          otherAccounts = otherAccounts + model.totalAccounts
        })
        const otherModels = {
          strategyName: 'Others',
          totalMarketValue: othersValue,
          totalAccounts: otherAccounts
        }
        const newData = [...data.slice(0, 4), otherModels]

        setTopAssignedModels(newData)
        setAllAssignedModels(data)
      } else {
        setTopAssignedModels(data)
        setAllAssignedModels(data)
      }
    } else {
      setTopAssignedModels([])
      setAllAssignedModels([])
    }
    setChartLoading(false)
  }

  const getTopAssignedModels = (queryParams = {}) => {
    setChartLoading(true)
    API
      .get('baseStrategyURL', `account-master/v1/${user?.userGroup}/strategies/totals`, {
        queryStringParameters: queryParams
      })
      .then(response => {
        if (response?.data?.strategyData) {
          filterTopAssignedModels([...response?.data?.strategyData])
          if (!Object.keys(queryParams).length || (Object.keys(queryParams).length === 1 && Object.keys(queryParams)[0] === 'cache-override')) {
            dispatch(storeModelsData(response?.data?.strategyData))
          }
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        setChartLoading(false)
      })
      .finally(() => {
        // setChartLoading(false)
      })
  }

  const getAccountsList = (queryParams = {}, page = 1) => {
    setTableLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/details`, { queryStringParameters: { ...queryParams, page, perpage: 200, ...{ ...user?.userGroup === 'adv-classic' ? { resources: encodeURIComponent(JSON.stringify(customHeaders)) } : {} } } })
      .then(response => {
        if (response?.data?.accountsSummaryDetails) {
          setAccounts([...response?.data?.accountsSummaryDetails])
          if (!Object.keys(queryParams).length || (Object.keys(queryParams).length === 1 && Object.keys(queryParams)[0] === 'cache-override')) {
            dispatch(storeAccountData(response?.data?.accountsSummaryDetails))
            const storeArray = []
            response?.data?.accountsSummaryDetails?.forEach((element) => {
              storeArray.push({
                accountName: element?.accountName,
                accountType: element?.accountType,
                accountCode: element?.accountCode,
                strategyId: element?.strategyId,
                custodianAccountNumber: element?.custodianAccountNumber,
                portSpecName: element?.portSpecName,
                accountId: element?.accountId,
                priority: element?.priority,
                totalMarketValue: element?.totalMarketValue
              })
            })
            localStorage.setItem('object', JSON.stringify(storeArray))
            setFetchedAllApiResponse(true)
          }
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        setTableLoading(false)
      })
  }

  const getAccountSummaryMetrics = (queryParams = {}) => {
    setSummaryLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/accounts/summary/total`, { queryStringParameters: queryParams })
      .then(response => {
        if (response?.data) {
          SetAccountSummaryMetrics((response?.data && response?.data[0]) || [])
          if (!Object.keys(queryParams).length || (Object.keys(queryParams).length === 1 && Object.keys(queryParams)[0] === 'cache-override')) {
            dispatch(storeSummaryData((response?.data && response?.data[0]) || []))
          }
          setSummaryLoading(false)
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        setSummaryLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getStrategyList = (queryParams = {}) => {
    API
      .get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies`, { queryStringParameters: { ...queryParams, page: 1, perpage: 100, ...{ ...user?.userGroup === 'adv-classic' ? { resources: encodeURIComponent(JSON.stringify(customHeaders)) } : {} } } })
      .then(response => {
        if (response?.data?.strategies) {
          setStrategies(response?.data?.strategies)
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getAUMData = (queryParams = {}) => {
    setAUMLoading(true)
    API
      .get('baseURL', `account-master/v1/${user?.userGroup}/aum/market-value`, {
        queryStringParameters: queryParams
      }).then(response => {
        if (response.data) {
          const convertedData = response.data?.map(data => ({
            ...data,
            dataDate: new Date(data?.dataDate).getTime()
          }))
          dispatch(storeMarketValueData(convertedData))
          setAUMData(convertedData)
          setAUMLoading(false)
        }
      })
      .catch(error => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        setAUMLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const openFilters = () => {
    setShowFilters(true)
  }

  const closeFilters = () => {
    setShowFilters(false)
    setTimeout(() => {
    }, 500)
  }

  const selectStrategyFilter = (event, strategy) => {
    if (event.target.checked) {
      setSelectedStrategyFilters([...selectedStrategyFilters, strategy])
    } else {
      const temp = selectedStrategyFilters.filter(item => ![strategy].includes(item))
      setSelectedStrategyFilters(temp)
    }
  }

  const selectRangeFilter = (name, value, callback) => {
    const index = selectedRangeFilters.findIndex(filter => filter.filterName === name)
    if (index > -1) {
      const temp = selectedRangeFilters
      temp[index].value = value
      setSelectedRangeFilters(temp)
    } else {
      setSelectedRangeFilters([...selectedRangeFilters, { filterName: name, value, callback }])
    }
    callback(value)
  }

  const applyFilters = () => {
    closeFilters()
    setSelectedFiltersCopy([
      ...(selectedStrategyFilters.map(filter => { return { filterType: 'strategy', ...filter } })),
      ...(selectedRangeFilters.map(filter => { return { filterType: 'range', ...filter } }))])
    updateQueryParams()
  }

  const updateQueryParams = () => {
    const queryParams = {}
    const cashdrift = selectedRangeFilters.find(filter => filter.filterName === 'Cash Drift')
    const trackingError = selectedRangeFilters.find(filter => filter.filterName === 'Tracking Error')
    const gainloss = selectedRangeFilters.find(filter => filter.filterName === 'Unrealized Gain/Loss')
    const accountage = selectedRangeFilters.find(filter => filter.filterName === 'Account Age')
    if (cashdrift) {
      queryParams['cd-min'] = cashdrift.value[0]
      queryParams['cd-max'] = cashdrift.value[1]
    }
    if (trackingError) {
      queryParams['te-min'] = trackingError.value[0]
      queryParams['te-max'] = trackingError.value[1]
    }
    if (gainloss) {
      queryParams['ungl-min'] = gainloss.value[0]
      queryParams['ungl-max'] = gainloss.value[1]
    }
    if (accountage) {
      queryParams['acc-age-min'] = accountage.value[0]
      queryParams['acc-age-max'] = accountage.value[1]
    }
    if (selectedStrategyFilters.length > 0) {
      queryParams.models = selectedStrategyFilters.map(filter => filter.strategyId)
    }
    getTopAssignedModels(queryParams)
    getAccountsList(queryParams)
    getAccountSummaryMetrics(queryParams)
  }

  const removeFilter = (filter) => {
    if (filter.filterType === 'strategy') {
      const temp = selectedStrategyFilters
      const index = temp.findIndex(strategy => strategy.strategyId === filter.strategyId)
      if (index > -1) {
        temp.splice(index, 1)
        setSelectedStrategyFilters(temp)
      }
    } else if (filter.filterType === 'range') {
      const temp = selectedRangeFilters
      const index = temp.findIndex(item => item.filterName === filter.filterName)
      if (index > -1) {
        temp[index].callback([0, 0])
        if (filter.filterName === 'Unrealized Gain/Loss') {
          temp[index].callback([-1, -1])
        }
        temp.splice(index, 1)
        setSelectedRangeFilters(temp)
      }
    }
    setSelectedFiltersCopy([
      ...(selectedStrategyFilters.map(filter => { return { filterType: 'strategy', ...filter } })),
      ...(selectedRangeFilters.map(filter => { return { filterType: 'range', ...filter } }))])
    updateQueryParams()
  }

  const removeAllFilters = (clearCache) => {
    setSelectedStrategyFilters([])
    setSelectedRangeFilters([])
    setShowFilters(false)
    setSelectedFiltersCopy([])
    getTopAssignedModels(clearCache ? { 'cache-override': true } : {})
    getAccountsList(clearCache ? { 'cache-override': true } : {})
    getAccountSummaryMetrics(clearCache ? { 'cache-override': true } : {})
    getStrategyList(clearCache ? { 'cache-override': true } : {})
    getAUMData()
  }

  return (
    <Box className='book-of-business-page'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography
          component='div'
          mb={2}
          // mt={2}
          sx={{
            fontFamily: 'Lora',
            fontWeight: 400,
            fontSize: '26px',
            color: '#34475A'
          }}
        >
          Book of Business
        </Typography>
        <Box>
          <Button
            variant='outlined'
            onClick={refreshDashboardData}
            sx={{
              border: '2px solid #dee2e6',
              padding: 1,
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              },
              marginBottom: '16px',
              // marginTop: '8px',
              marginRight: '8px'
            }}
            className='tooltip-trade'
          >
            <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
            <span
              className='tooltiptext'
              style={{ marginLeft: '-15px' }}
            >
              Refresh
            </span>
          </Button>
          <Button
            variant='outlined'
            onClick={openFilters}
            sx={{
              border: '2px solid #dee2e6',
              padding: 1,
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              },
              marginBottom: '16px',
              // marginTop: '8px',
              marginRight: '0px'
            }}
            className='tooltip-trade'
          >
            <img src={filterIcon} alt='filter' height={20} width={20} />
            <span
              className='tooltiptext'
              style={{ marginLeft: '-15px' }}
            >
              Filter
            </span>
          </Button>
        </Box>
      </Box>

      <Grid container spacing={{ xs: 2, lg: 3 }} alignContent='stretch'>
        <Grid item xs={12} lg={12} order={{ xs: 1, lg: 1 }}>
          <Statistics accountSummaryMetrics={accountSummaryMetrics} isLoading={summaryLoading} />
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 2, lg: 2 }} mt={{ xs: 4, lg: 0 }}>
          <TopAssignedModels topAssignedModels={topAssignedModels} allAssignedModels={allAssignedModels} isLoading={chartLoading} />
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 2, lg: 2 }} mt={{ xs: 4, lg: 0 }}>
          <MarketValueChart data={AUMData} isLoading={AUMLoading} />
        </Grid>
      </Grid>
      <AccountListContext.Provider value={{ accounts }}>
        <AllAccounts selectedFilters={selectedFiltersCopy} removeFilter={removeFilter} isLoading={tableLoading} />
      </AccountListContext.Provider>
      <FilterMenu showFilters={showFilters} closeFilters={closeFilters} removeAll={removeAllFilters} strategies={strategies} selectRangeFilter={selectRangeFilter} selectedStrategyFilters={selectedStrategyFilters} selectStrategyFilter={selectStrategyFilter} applyFilters={applyFilters} />
    </Box>
  )
}

export default BookOfBusiness
