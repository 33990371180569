import { createContext, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useDispatch } from 'react-redux'
import { API, Auth } from 'aws-amplify'
import * as AWS from 'aws-sdk'
import { clearStore } from '../store/dashboard-reducer/dashboard-reducer'
import { LogoProvider } from './SponsorLogoContext'
import { useErrorToast } from '../hooks/useErrorToast'
import { storeAclUserGrp, storeUserGroup } from '../store/user-reducer/user-reducer'
import { ACCESS_LEVEL } from '../contstants/constants'
import { useTourContext } from './TourContext'
import { useMenuConfig } from '../hooks/useMenuConfig'

const AuthContext = createContext(null)

const loginsKey = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`

const cognitoIdentity = new AWS.CognitoIdentity({
  region: process.env.REACT_APP_IDENTITY_POOL_REGION
})

export const AuthProvider = ({ children }) => {
  const storedUserAclData = localStorage.getItem('user-acl-data')
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const { showError } = useErrorToast()
  const dispatch = useDispatch()
  const { setIsNewUser } = useTourContext()
  const [isAclFetching, setIsAclFetching] = useState(false)
  const sidebarMenuList = useMenuConfig()
  const [curPath, setCurrPath] = useState('/')
  const [userAclData, setUserAclData] = useState(storedUserAclData ? JSON.parse(storedUserAclData) : {})
  const [noAclError, setNoAclError] = useState(false)
  const [userGroupArray, setUserGroupsArray] = useState([])

  const storeUser = (value) => {
    setLoading(true)
    setUser(value)
    setLoading(false)
  }

  const storeAllUserGroups = (value) => {
    setUserGroupsArray(value)
  }

  useEffect(() => {
    if (user && user?.sub && user?.userGroup && (storedUserAclData === null || storedUserAclData === '[]') && (user?.userGroup === 'adv-classic' || user?.userGroup === 'pm')) {
      setIsAclFetching(true)
      fetchACLAccessApi(user?.sub)
    } else {
      // store the cur accessible path to redirect to home screen (to cur accessible path) when page not found
      getCurrentRedirectionPath(JSON.parse(storedUserAclData))
    }
  }, [user])

  const refreshACL = (props, cache) => {
    fetchACLAccessApi(props, cache)
  }

  // redirect to the first accessible path form ACL
  let prioritizedPath = '/book-of-business'
  const getCurrentRedirectionPath = (aclData) => {
    for (const data of sidebarMenuList) {
      // compare menuConfig with the ACL to get first accessible path
      const serviceExists = aclData?.services?.find(service => service?.Service === data?.moduleCd)
      if (serviceExists) {
        // menuConfig paths without subMenus
        if (data?.path) {
          prioritizedPath = data?.path
          break
          // menuConfig subMenu paths
        } else if (data?.paths && data?.paths?.length > 0) {
          const currPath = data?.paths?.filter((path) => checkCurrentRedirectRouteAccess(path?.path, ACCESS_LEVEL.ROUTE_ACCESS, aclData) === true)
          prioritizedPath = currPath?.length > 0 ? currPath[0]?.path : prioritizedPath
          break
        }
      }
    }
    setCurrPath(prioritizedPath)
  }

  const fetchACLAccessApi = (props, cache, retries = 2) => {
    let hasError = false
    API.get('baseAclURL', `user-access-control/v1/user/${props}`)
      .then((res) => {
        if (res && res.success && res.data) {
          // call getCurrentRedirectionPath function to get ACL accessible path to redirect after login
          getCurrentRedirectionPath(res.data)
          setUserAclData(res.data)
          localStorage.setItem('user-acl-data', JSON.stringify(res.data))
          setIsAclFetching(false)
        }
      })
      .catch((error) => {
        hasError = true
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        setIsAclFetching(false)
      }).finally(() => {
        // if api fails for the first time retry it again
        if (hasError && retries > 0) {
          retries -= 1
          setTimeout(() => {
            setIsAclFetching(true)
            fetchACLAccessApi(props, cache, retries)
          }, 1000)
        } else if (hasError) {
          showError('Failed to load ACL')
        }
      })
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    setIsNewUser(false) // set isNewUser false before fetching latest user.
    try {
      const currentGroup = localStorage.getItem('userGroup')
      const roleArn = localStorage.getItem('roleArn')
      const userData = localStorage.getItem('userData')
      const aclCurrentGrp = localStorage.getItem('aclUserGrp')
      // handle switch role
      if (currentGroup && roleArn && roleArn !== 'undefined') {
        try {
          const user = await Auth.currentAuthenticatedUser()
          const credentials = await Auth.currentUserCredentials()
          // reassign current user credentials with userData credentials from localStorage (set from switchRoles function)
          const tokenID = user?.signInUserSession?.idToken?.jwtToken
          const identityParams = {
            IdentityId: credentials?.identityId,
            CustomRoleArn: roleArn,
            Logins: {
              [loginsKey]: tokenID
            }
          }
          // get switched user role credentials from the identity params
          await cognitoIdentity?.getCredentialsForIdentity(identityParams)
            ?.promise().then((credentialData) => {
              const sessionData = {
                accessKeyId: credentialData?.Credentials?.AccessKeyId,
                sessionToken: credentialData?.Credentials?.SessionToken,
                secretAccessKey: credentialData?.Credentials?.SecretKey,
                expireTime: credentialData?.Credentials?.Expiration,
                identityId: credentialData?.IdentityId,
                expired: false
              }
              // assign new credentials (sessionData) to current auth user credentials
              Object.assign(credentials, sessionData)
            }).catch((error) => {
              showError(error.message)
              Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            })

          // set user credentials from current authenticated user
          setUser({
            email: user.attributes.email,
            name: user.attributes.name,
            userGroup: currentGroup,
            preferredMFA: user.preferredMFA,
            jwtToken: user?.signInUserSession?.idToken?.jwtToken,
            sub: user?.signInUserSession?.idToken?.payload?.sub,
            allowedGroups: user?.signInUserSession?.idToken?.payload['cognito:groups'],
            currUserName: user?.signInUserSession?.idToken?.payload['cognito:username'],
            userData,
            roleArn
          })
          if (user?.signInUserSession?.idToken?.payload && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] === 'true') {
            setIsNewUser(true)
          }
          // localStorage.removeItem('currentGroup')
          // localStorage.removeItem('userData')
          // localStorage.removeItem('roleArn')
          dispatch(storeUserGroup(currentGroup))
        } catch (error) {
          if (error === 'The user is not authenticated' || error === 'Refresh Token has expired') {
            storeUser(null)
            dispatch(clearStore('RESET'))
            dispatch(storeUserGroup(''))
            setLoading(false)
          }
          showError((error === 'The user is not authenticated' || error === 'Refresh Token has expired') ? 'Your session has expired. Please re-login' : error)
          Sentry.captureException(error)
        }
      }
      // handle initial login page refresh
      else if (currentGroup && (currentGroup !== 'pm' ? aclCurrentGrp && aclCurrentGrp !== 'undefined' : true)) {
        try {
          setLoading(true)
          const user = await Auth.currentAuthenticatedUser()
          setUser({
            email: user.attributes.email,
            name: user.attributes.name,
            userGroup: currentGroup,
            aclUserGrp: aclCurrentGrp,
            preferredMFA: user.preferredMFA,
            jwtToken: user?.signInUserSession?.idToken?.jwtToken,
            sub: user?.signInUserSession?.idToken?.payload?.sub,
            allowedGroups: user?.signInUserSession?.idToken?.payload['cognito:groups'],
            currUserName: user?.signInUserSession?.idToken?.payload['cognito:username']
          })
          if (user?.signInUserSession?.idToken?.payload && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] === 'true') {
            setIsNewUser(true)
          }
          // localStorage.removeItem('currentGroup')
          dispatch(storeUserGroup(currentGroup))
          dispatch(storeAclUserGrp(aclCurrentGrp))
        } catch (error) {
          if (error === 'The user is not authenticated' || error === 'Refresh Token has expired') {
            storeUser(null)
            dispatch(clearStore('RESET'))
            dispatch(storeUserGroup(''))
            dispatch(storeAclUserGrp(''))
            setLoading(false)
          }
          showError((error === 'The user is not authenticated' || error === 'Refresh Token has expired') ? 'Your session has expired. Please re-login' : error)
          Sentry.captureException(error)
        }
      } else {
        try {
          setLoading(true)
          const user = await Auth.currentAuthenticatedUser()
          await API.get('baseUriUser', `user/v1/user-groups/${user.attributes.sub}`)
            .then((response) => {
              // store cognito preferred user if user is present
              const userGroup = response?.data?.find((group) => user.signInUserSession?.idToken?.payload && group.groupArn === user.signInUserSession?.idToken?.payload['cognito:preferred_role'])?.groupName
              if (userGroup) {
                setUser({
                  email: user.attributes.email,
                  name: user.attributes.name,
                  userGroup,
                  preferredMFA: user.preferredMFA,
                  jwtToken: user?.signInUserSession?.idToken?.jwtToken,
                  sub: user?.signInUserSession?.idToken?.payload?.sub,
                  allowedGroups: user?.signInUserSession?.idToken?.payload['cognito:groups'],
                  currUserName: user?.signInUserSession?.idToken?.payload['cognito:username']
                })
                if (user?.signInUserSession?.idToken?.payload && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] === 'true') {
                  setIsNewUser(true)
                }
                localStorage.setItem('userGroup', userGroup)
                dispatch(storeUserGroup(userGroup))
              }
            })
            .catch(() => {
              setUser({
                email: user.attributes.email,
                name: user.attributes.name,
                preferredMFA: user.preferredMFA,
                jwtToken: user?.signInUserSession?.idToken?.jwtToken,
                sub: user?.signInUserSession?.idToken?.payload?.sub,
                allowedGroups: user?.signInUserSession?.idToken?.payload['cognito:groups'],
                currUserName: user?.signInUserSession?.idToken?.payload['cognito:username']
              })
              if (user?.signInUserSession?.idToken?.payload && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] && user?.signInUserSession?.idToken?.payload['custom:newClientUser'] === 'true') {
                setIsNewUser(true)
              }
              dispatch(storeUserGroup(''))
            })
            .finally(() => {
              setLoading(false)
            })
        } catch (error) {
          if (error === 'The user is not authenticated' || error === 'Refresh Token has expired') {
            storeUser(null)
            dispatch(clearStore('RESET'))
            dispatch(storeUserGroup(''))
            setLoading(false)
          }
        }
      }
    } catch (err) {
      showError((err === 'The user is not authenticated' || err === 'Refresh Token has expired') ? 'Your session has expired. Please re-login' : err)
      Sentry.captureException(err)
      setUser(null)
    } finally {
      setLoading(false)
    }
  }

  const checkAccess = (moduleName, level, subModuleData) => {
    if (user && userAclData) {
      // '*' means has all access for that service or resource
      const modulePermission = userAclData?.services?.find(data => data?.Service === moduleName)
      if (!modulePermission || modulePermission?.Resources?.length === 0) return false
      // ex. checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL }))
      // check sub moudle access which is the first level resource under any service
      const checkSubModulePermission = (subModuleName, moduleName) => {
        const allowedResources = modulePermission?.Resources
        let result = false
        if (allowedResources) {
          // if there is only one resource and it has '*' means it has all access
          if (allowedResources?.length === 1 && allowedResources[0] === '*') {
            result = result || true
          } else {
            if (modulePermission?.Resources?.length > 0) {
              for (const resource of allowedResources) {
                //     modulename  : subModuleName : *
                // ex. account-review : account-dashboard : *
                const parts = resource.split(':')
                // last level resource are stored with '*' in ACl check for provided sub module name in all resources
                // ex: all-requests:update-request:*
                result = result || (
                  (parts?.length > 0 && parts?.includes(subModuleName)) ||
                                (parts?.length > 0 && parts?.includes(subModuleName) && parts?.includes('*'))
                )
              }
            }
          }
          return result
        }
      }
      // check component access which is the first level resource under any resource
      const checkComponentPermission = (componentName, subModuleName, id, moduleName) => {
        // ex : checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
        //   { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })
        const allowedResources = modulePermission?.Resources
        let result = false
        if (allowedResources) {
          // If the allowedResources contains just '*', access is granted
          if (allowedResources?.length === 1 && allowedResources[0] === '*') {
            result = result || true
          } else {
            if (modulePermission?.Resources?.length > 0) {
              for (const resource of allowedResources) {
                const parts = resource.split(':')
                const idList = userAclData?.services?.find(data => data?.Service === moduleName) && userAclData?.accountIds?.length > 0 ? userAclData?.accountIds : []
                // const foundRes = allowedResources?.length > 0 && allowedResources?.find((data) => data.includes('*'))
                // ex: account-dashboard:* // if parent resource is givin full access then return true
                if (parts?.includes(subModuleName) && parts?.includes('*') && parts.length === 2) {
                  result = result || true
                  // check for the resource access under the component
                  // ex: account-review:account-dashboard:* then return true
                }
                else {
                  // ex. account-dashboard:account-attributes:*
                  // find exact resource with component and sub-module name
                  if (
                    (parts?.includes(componentName) && parts?.includes(subModuleName)) ||
                    (parts?.includes(componentName) && parts?.includes(subModuleName) && parts?.includes('*')) ||
                    (parts?.includes(componentName) && parts?.includes(subModuleName) && idList?.includes(id))
                  ) {
                    result = result || true
                  }
                }
              }
            }
          }
        }
        return result
      }
      switch (level) {
        case ACCESS_LEVEL.MODULE_ACCESS:
          return Boolean(modulePermission)
        case ACCESS_LEVEL.SUB_MODULE_ACCESS:
          return Boolean(checkSubModulePermission(subModuleData?.subModuleName, moduleName))
        case ACCESS_LEVEL.COMPONENT_ACCESS:
          return Boolean(checkComponentPermission(subModuleData?.component_name, subModuleData?.subModuleName, subModuleData?.id, moduleName))
        case ACCESS_LEVEL.ROUTE_ACCESS: {
          return false
        }
        default:
          return false
      }
    }
    return false
  }

  // copy of route access check function which will be used when ACL variable is not set yet
  const checkCurrentRedirectRouteAccess = (pathName, accessLevel, aclData) => {
    let result = false
    const paths = pathName?.split('/')?.filter(str => str !== '')
    if (aclData) {
      // check if current route contains id
      const lastPath = paths?.slice(-1)[0]
      if (paths?.includes(lastPath)) {
        // check in the services if any of the services matched the current service name and that path does not contains id
        const routesWithoutIds = aclData?.services && aclData?.services?.length > 0 ? aclData?.services?.find(data => data?.Service === paths[0]) : undefined
        const allowedResources = routesWithoutIds?.Resources
        if (allowedResources && allowedResources?.length > 0) {
          for (const resource of allowedResources) {
            const parts = resource.split(':')
            if (parts.includes(lastPath)) {
              result = true
              break
            }
          }
        }
      }
    }
    return result
  }

  const checkRouteAccess = (pathName, accessLevel) => {
    let result = false
    const paths = pathName?.split('/')?.filter(str => str !== '')
    if (user && userAclData) {
      // find module permission that matches the current serviceId (module) and resourceId (sub-module) with accountIds
      // exclude 'model' and 'aggregate' modules
      // ex: for route 'account-review/account-dashboard/008b901e-99b5-4084-8faf-4bd2ac080b3f' serviceId will be 'account-review' and resourceId will be 'account-dashboard'
      const modulePermission = userAclData?.accountIds && userAclData?.accountIds?.length > 0
        ? userAclData?.accountIds?.find(modules =>
          modules?.serviceId === paths[0] &&
            modules?.resourceId === paths[1] &&
            modules?.serviceId !== 'model' &&
            modules?.serviceId !== 'aggregate'
        )
        : undefined

      // find account ids associated with the 'book-of-business' module
      // ex: this will check for route access for account-review dashboard when we click on specific account in book of business
      const bobAccountIdList = userAclData?.accountIds && userAclData?.accountIds?.length > 0
        ? userAclData?.accountIds?.find(modules =>
          modules?.serviceId === 'book-of-business' &&
            modules?.resourceId === 'book-of-business'
        )
        : undefined

      // find services that do not require specific account ids
      const routesWithoutIds = userAclData?.services && userAclData?.services?.length > 0
        ? userAclData?.services?.find(data => data?.Service === paths[0])
        : undefined

      const allowedServiceResources = routesWithoutIds?.Resources

      if (accessLevel === ACCESS_LEVEL.ROUTE_ACCESS) {
        // if the path is reports with request id consider last second parameter as account id else last parameter as account id
        const lastPath = paths?.length > 3 && paths?.includes('account-review') && paths?.includes('reports')
          ? paths?.slice(-1)[1]
          : paths?.slice(-1)[0]

        const allowedResources = modulePermission?.accountIds

        // if all resources are allowed ('*'), grant access
        if (allowedResources && allowedResources?.length === 1 && allowedResources[0] === '*') {
          result = true
        } else {
          // if accessing 'account-review', check if the account matches a 'book-of-business' account and it includes accountIds
          if (bobAccountIdList && bobAccountIdList?.accountIds?.length >= 1 && allowedServiceResources &&
              paths[0] === 'account-review' && lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/)) {
            if (allowedServiceResources) {
              // check if the allowed resources include the matching path and accountIds
              for (const resource of allowedServiceResources) {
                const parts = resource.split(':')
                result = result || (parts?.includes(paths[1]) && bobAccountIdList?.accountIds?.includes(lastPath))
              }
            } else {
              result = result || (bobAccountIdList?.accountIds?.includes(lastPath))
            }
          }
          // for other paths expect 'account-review' need to check for matching path in acl and check account id in its own module account list
          else if (modulePermission && allowedResources && allowedResources?.length >= 1 && lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/)) {
            if (paths?.length > 1 && paths?.includes(lastPath) && allowedServiceResources && allowedServiceResources?.length > 0) {
              // ensure the resources match the required path and account ID
              for (const resource of allowedServiceResources) {
                const parts = resource.split(':')
                result = result || (parts?.includes(paths[0]) && parts?.includes(paths[1]) &&
                  modulePermission?.serviceId === paths[0] && modulePermission?.resourceId === paths[1] &&
                  modulePermission?.accountIds?.includes(lastPath))
              }
            }
          } else {
            // For paths without specific ids check exact path in acl
            if (!lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/) && paths?.includes(lastPath)) {
              const allowedResources = routesWithoutIds?.Resources
              if (allowedResources && allowedResources?.length === 1 && allowedResources[0] === '*') {
                result = true
              } else if (allowedResources && allowedResources?.length > 0) {
                // check if any resource matches the current path
                for (const resource of allowedResources) {
                  const parts = resource.split(':')
                  result = result || (parts?.includes(paths[0]) && parts?.includes(paths[1]) && parts?.includes(lastPath)) ||
                                     (parts?.includes(paths[0]) && parts?.includes(lastPath)) ||
                                     (parts?.includes(paths[1]) && parts?.includes(lastPath)) ||
                                     (parts?.includes(paths[0]) && parts?.includes(paths[1]))
                }
              }
            } else {
              // special case: handle 'model' and 'aggregate' services and grant access if resource is present in acl for all account ids
              const allowedResources = routesWithoutIds?.Resources
              if (allowedResources && allowedResources?.length === 1 && allowedResources[0] === '*' &&
                  (routesWithoutIds?.Service === 'model' || routesWithoutIds?.Service !== 'aggregate')) {
                result = true
              } else if (allowedResources && allowedResources?.length > 0 && !lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/)) {
                // check if the service or resource matches 'model' or 'aggregate'
                for (const resource of allowedResources) {
                  const parts = resource.split(':')
                  result = result || (parts?.includes(paths[0]) || parts?.includes(paths[1]))
                }
              } else if ((user?.userGroup === 'pm' || user?.userGroup === 'adv-classic') &&
                          lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/) && allowedResources && allowedResources?.length > 0) {
                // ensure the user belongs to the correct group and the path matches a valid accountId
                for (const resource of allowedResources) {
                  const parts = resource.split(':')
                  result = result || ((parts?.includes(paths[0]) && lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/)) ||
                                      (parts?.includes(paths[1]) && lastPath?.match(/^(?=.*[0-9])[a-zA-Z0-9-]{1,49}$/)))
                }
              }
            }
          }
        }
      }
    }

    return result
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          storeUser,
          loading,
          checkAccess,
          checkRouteAccess,
          userAclData,
          refreshACL,
          setIsAclFetching,
          isAclFetching,
          setNoAclError,
          noAclError,
          curPath,
          setCurrPath,
          setUserAclData,
          storeAllUserGroups,
          getCurrentRedirectionPath,
          checkCurrentRedirectRouteAccess,
          userGroupArray
        }}
      >
        <LogoProvider>
          {children}
        </LogoProvider>
      </AuthContext.Provider>
    </>
  )
}

export const useAuth = () => useContext(AuthContext)
