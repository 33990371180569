import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { Autocomplete, Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import searchIcon from '../assets/images/top-search-icon.svg'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import { useAuth } from '../contexts/AuthContext'
import { ReactComponent as TradeIcon } from '../../src/assets/images/trade-example-icon.svg'
import { storeSelectedSponsor, storeSponsorList } from '../store/user-reducer/user-reducer'
import { PAGE_LIST } from './menuConfig'

const PageSearch = ({ setCurrentZIndex }) => {
  const [selectedEntity, setSelectedEntity] = useState({})
  const { accountData } = useSelector(state => state.dashboard)
  const [optionList, setOptionList] = useState({})
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)
  const storedSponsorId = localStorage.getItem('ips-sponsor-id')
  const [sponsorsList, setSponsorsList] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [filterInputValue, setFilterInputValue] = useState('')
  const currentIndex = useRef(-1)
  const [filteredOption, setFilteredOption] = useState([])
  const { user } = useAuth()
  const navigate = useNavigate()
  const [_, setSponsorId] = useState(storedSponsorId ? (typeof storedSponsorId === 'object' && JSON.parse(storedSponsorId).sponsorId) ? storedSponsorId : { sponsorId: '-1' } : { sponsorId: '-1' })
  const dispatch = useDispatch()
  const { userGroup, allowedSponsorList } = useSelector((state) => state.user)
  const [userGrp, setUserGrp] = useState(userGroup)
  const [lockedInValue, setLockedInValue] = useState('')
  const [open, setOpen] = useState(false)
  const [backDrop, setBackDrop] = useState(false)
  const [newValueSelected, setNewValueSelected] = useState(false)
  const location = useLocation()
  const [placeHolder, setPlaceHolder] = useState('')
  const [placeHolderMessage, setPlaceHolerMessage] = useState('')
  const hint = useRef('')
  const inputRef = useRef(null)
  const exampleRef = useRef(-1)
  const [isFocused, setIsFocused] = useState(null)
  const [closeOnClick, setCloseOnClick] = useState(false)
  const [noData, setNoData] = useState('')

  const [color, setColor] = useState('')

  const chipRef = useRef(null)
  const [chipWidth, setChipWidth] = useState(0)

  useEffect(() => {
    if (chipRef.current) {
      setChipWidth(chipRef.current.offsetWidth)
    }
  }, [placeHolder])

  const clearFields = () => {
    inputRef.current.blur()
    setPlaceHolerMessage('')
    setBackDrop(false)
    hint.current = ''
    setPlaceHolder('')
    setInputValue('')
    setLockedInValue('')
    setFilterInputValue('')
    setValue(null)
    exampleRef.current = -1
    setOpen(false)
  }

  useEffect(() => {
    if (backDrop) {
      setCurrentZIndex(99)
    } else {
      setCurrentZIndex(98)
      clearFields()
    }
  }, [backDrop])

  const getJson = () => {
    // Convert array to a simplified format with only 'name' and 'path'
    const searchEntities = {
      entities: [
        {
          name: 'pages',
          data: PAGE_LIST,
          searchFunction: (req) => PAGE_LIST?.filter(menu => menu?.name?.toLowerCase()?.replace(/\s+/g, ' ').trim().startsWith(req?.toLowerCase())),
          priority: 2
        },
        {
          name: 'accounts',
          data: accountData,
          priority: 1,
          roles: ['adv-classic', 'pm'],
          searchFunction: (req) => accountData?.filter(account => account?.accountName?.toLowerCase().replace(/\s+/g, ' ').trim().startsWith(req?.toLowerCase()))
        },
        {
          name: 'sponsors',
          data: allowedSponsorList,
          priority: 3,
          searchFunction: (req) => (allowedSponsorList || sponsorsList)?.filter(sponsor => sponsor?.sponsorName?.toLowerCase().replace(/\s+/g, ' ').trim().startsWith(req?.toLowerCase()))
        }
      ]
    }
    return searchEntities
  }

  // reset on close icon click
  useEffect(() => {
    if (closeOnClick) {
      clearFields()
    }
  }, [closeOnClick])

  const getSponserIds = async () => {
    if (allowedSponsorList?.length) {
      setSponsorsList(allowedSponsorList)
      if (user?.userGroup === 'adv-classic') {
        setSponsorId(allowedSponsorList[0])
      }
      if (typeof storedSponsorId === 'string') {
        const selectedSponsor = allowedSponsorList?.find((sponsor) => sponsor?.sponsorId === storedSponsorId)
        if (selectedSponsor) {
          setSponsorId(selectedSponsor)
        }
      }
    } else {
      API.get('baseSponserURL', `data-maintenance/v1/${user?.userGroup}/sponsorIds`)
        .then(response => {
          if (user?.userGroup === 'adv-classic') {
            setSponsorId(response?.data?.data[0])
          }
          if (response?.data && response?.data?.data) {
            setSponsorsList(response?.data?.data)
            dispatch(storeSponsorList(response?.data?.data))
            if (typeof storedSponsorId === 'string') {
              const selectedSponsor = response?.data?.data?.find((sponsor) => sponsor?.sponsorId === storedSponsorId)
              if (selectedSponsor) {
                setSponsorId(selectedSponsor)
              }
            }
          }
        })
        .catch(error => {
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  // short cut key to open search
  const keyDownHandler = (event) => {
    if (event.ctrlKey && event.key === 'k') {
      event.preventDefault()
      inputRef.current.focus()
      setBackDrop(true)
    }
  }

  // short cut key handler to open search
  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  useEffect(() => {
    if (user?.userGroup) {
      setUserGrp(user?.userGroup)
      getSponserIds()
    }
  }, [user])

  useEffect(() => {
    setOptionList(getJson())
  }, [accountData, allowedSponsorList])

  useEffect(() => {
    setFilteredOption(options?.map((data) => data))
  }, [options])

  useEffect(() => {
    // set lockedInValue for user and sponsors
    if (inputValue?.split(' / ')?.length === 2) {
      setLockedInValue(filterInputValue?.substring(0, inputValue?.indexOf(' / ') + 3))
    }
  }, [inputValue])

  // set newValueSelected entity data
  useEffect(() => {
    if (filterInputValue?.trim()?.length > 0) {
      const [typeName, _] = filterInputValue?.split(' / ')
      const results = optionList?.entities?.sort((a, b) => a.priority - b.priority)?.map(entity => {
        const searchResult = entity?.searchFunction(typeName)
        return {
          name: entity?.name,
          results: searchResult,
          typeName
        }
      })
      const res = results?.find((data) => data?.results?.length > 0)
      if (res && Object.keys(res)?.length > 0) {
        setSelectedEntity(res)
      }
    } else {
      setSelectedEntity({})
    }
  }, [filterInputValue])

  // clear field and backdrop on path / location change
  useEffect(() => {
    clearFields()
  }, [location])

  // handle input field changes
  useEffect(() => {
    if ((inputValue && inputValue?.trim()?.length > 0) || placeHolder) {
      handleFieldInputChange(inputValue)
    } else {
      const filteredPageNames = PAGE_LIST?.filter(menu => menu?.roles?.includes(userGrp))?.map(menu => ({ value: menu?.name, group: 'Pages', label: <WebAssetIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> }))
      if (filteredPageNames) {
        setOptions([...filteredPageNames])
        hint.current = ''
      }
    }
  }, [inputValue, userGrp, placeHolder])

  const handleFieldInputChange = (request) => {
    let results

    // when user search for specific account or sponsor
    if (request?.split(' / ')?.length === 2) {
      const searchQuery = request?.split(' / ').pop().trim()
      results = optionList?.entities[0]?.searchFunction(searchQuery)

      if (selectedEntity?.name === 'accounts') {
        const filteredOptions = PAGE_LIST?.filter(page => page?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()) && page.dependentEntityKey === 'accountId')
        const pageNames = filteredOptions.map(page => ({ value: page.name, group: 'Pages', label: <WebAssetIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> }))
        if (placeHolder && placeHolder === 'Accounts') {
          if (pageNames?.length <= 0 && request?.split(' / ')?.length === 2) {
            setNoData('Page')
          } else {
            setNoData('')
          }
          setOptions([...pageNames])
          if (options.length > 0 && inputValue.trim().length > 0) {
            // show helper hint as user types
            const remainingCharacters = pageNames[0]?.value?.slice(searchQuery?.length)
            hint.current = inputValue + (remainingCharacters || '')
          } else {
            hint.current = ''
          }
        } else {
          setOptions([...pageNames])
          if (options.length > 0 && inputValue.trim().length > 0) {
            const remainingCharacters = pageNames[0]?.value?.slice(searchQuery?.length)
            hint.current = inputValue + (remainingCharacters || '')
          } else {
            hint.current = ''
          }
        }
      } else if (selectedEntity?.name === 'sponsors') {
        const filteredOptions = PAGE_LIST?.filter(page => page?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) && page?.dependentEntityKey === 'sponsorId')
        const sponsorNames = filteredOptions?.map(page => ({ value: page?.name, group: 'Sponsors', label: <CorporateFareOutlinedIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> }))

        if (placeHolder && placeHolder === 'Sponsors') {
          setOptions([...sponsorNames])
          if (options.length > 0 && inputValue.trim().length > 0) {
            // show helper hint as user types
            const remainingCharacters = sponsorNames[0]?.value?.slice(searchQuery?.length)
            hint.current = inputValue + (remainingCharacters || '')
          } else {
            hint.current = ''
          }
        } else {
          setOptions([...sponsorNames])
          if (options.length > 0 && inputValue.trim().length > 0) {
            const remainingCharacters = sponsorNames[0]?.value?.slice(searchQuery?.length)
            hint.current = inputValue + (remainingCharacters || '')
          } else {
            hint.current = ''
          }
        }
      }
    } else {
      results = optionList?.entities?.map(entity => {
        const searchResult = entity?.searchFunction(request)
        return {
          name: entity?.name,
          results: searchResult,
          priority: entity?.priority,
          roles: entity?.roles,
          request
        }
      })

      // priority vise option sorting
      const validResults = results?.filter(result => result?.results && result?.results?.length > 0)
      const sortedResults = validResults?.sort((a, b) => a.priority - b.priority)

      const groupedOptions = {}

      sortedResults.forEach(result => {
        switch (result?.name) {
          case 'accounts': {
            if (result?.roles?.includes(userGrp)) {
              const accountNames = result?.results?.map((data) => ({
                value: data?.accountName.replace(/\s+/g, ' ').trim(),
                group: 'Accounts',
                label: <PermIdentityIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} />
              }))
              if (placeHolder && placeHolder.toLowerCase() === result?.name) {
                groupedOptions.accounts = [...(groupedOptions?.accounts || []), ...accountNames]
              } else if (!placeHolder) {
                groupedOptions.accounts = [...(groupedOptions?.accounts || []), ...accountNames]
              }
            }
            break
          }
          case 'pages': {
            if (result?.results) {
              const pageNames = result?.results?.filter((data) => data?.roles?.includes(userGrp))?.map((page) => ({
                value: page?.name,
                group: 'Pages',
                label: <WebAssetIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} />
              }))
              if (placeHolder && placeHolder.toLowerCase() === result?.name) {
                groupedOptions.pages = [...(groupedOptions?.pages || []), ...pageNames]
              } else if (!placeHolder) {
                groupedOptions.pages = [...(groupedOptions?.pages || []), ...pageNames]
              }
            }
            break
          }
          case 'sponsors': {
            if (result?.name) {
              const pageNames = result?.results?.map((page) => ({
                value: page?.sponsorName,
                group: 'Sponsors',
                label: <CorporateFareOutlinedIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} />
              }))
              if (placeHolder && placeHolder.toLowerCase() === result?.name) {
                groupedOptions.pages = [...(groupedOptions?.pages || []), ...pageNames]
              } else if (!placeHolder) {
                groupedOptions.pages = [...(groupedOptions?.pages || []), ...pageNames]
              }
            }
            break
          }
          default:
            hint.current = ''
            break
        }
      })

      const options = Object.values(groupedOptions)?.flat()?.map(option => option)
      if (options.length > 0 && inputValue.trim().length > 0) {
        // show helper hint as user types
        const remainingCharacters = options[0].value.slice(request.length)
        hint.current = inputValue + remainingCharacters
      } else {
        hint.current = ''
      }
      setOptions([...options])
    }
  }

  // when user jump to specific page with enter key
  const handleFilterInputChange = (value) => {
    const page = selectedEntity?.results?.find((page) => page?.name?.toLowerCase() === value?.toLowerCase())
    const [type, name] = value?.split(' / ')
    // when user is searching for specific account to sponsor ex { abc/Dashboard }
    if (type !== undefined && name !== undefined) {
      if (selectedEntity?.name === 'accounts') {
        const accId = selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim().toLowerCase() === type?.replace(/\s+/g, ' ').trim().toLowerCase())?.accountId
        if (accId) {
          navigate(PAGE_LIST?.find((page) => page?.name.toLowerCase() === name?.toLowerCase())?.path?.replace(':accountId', accId))
          setOpen(false)
          setBackDrop(false)
        }
        // setValue(lockedInValue + name)
        setValue(null)
      } else {
        const spnId = selectedEntity?.results?.find((spn) => spn?.sponsorName.replace(/\s+/g, ' ').trim().toLowerCase() === type?.replace(/\s+/g, ' ').trim().toLowerCase())?.sponsorId
        if (spnId) {
          localStorage.setItem('ips-sponsor-id', spnId)
          dispatch(storeSelectedSponsor(spnId))
          navigate(PAGE_LIST?.find((page) => page?.name.toLowerCase() === name.toLowerCase())?.path)
          setOpen(false)
          setBackDrop(false)
        }
        // setValue(lockedInValue + name)
        setValue(null)
      }
    } else if (page) {
      if (page) {
        clearFields()
        navigate(page?.path?.replace(':accountId', ''))
      }
    } else {
      // input values matches selectedEntity then search from specific type  ex. accounts
      switch (selectedEntity?.name) {
        case 'accounts': {
          if (selectedEntity?.results?.length > 0) {
            if (selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim().toLowerCase() === value?.replace(/\s+/g, ' ').trim().toLowerCase())) {
              setValue(value + ' / ')
              const options = PAGE_LIST.reduce((acc, page) => {
                if (page?.dependentEntityKey === 'accountId') {
                  acc.push({ value: page.name, group: 'Pages', label: <WebAssetIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> })
                }
                return acc
              }, [])
              setOptions([...options])
              setFilteredOption([...options])
              if (!name) {
                // show helper hint as user types
                hint.current = hint.current + options[0]?.value
              } else {
                hint.current = lockedInValue + filteredOption[currentIndex.current]?.value + ' / '
              }
            }
          }
          break
        }
        case 'sponsors' : {
          if (selectedEntity?.results?.length > 0) {
            if (selectedEntity?.results?.find((spn) => spn.sponsorName.replace(/\s+/g, ' ').trim().toLowerCase() === value?.replace(/\s+/g, ' ').trim().toLowerCase())) {
              setValue(value + ' / ')
              const options = PAGE_LIST.reduce((sponsor, page) => {
                if (page?.dependentEntityKey === 'sponsorId') {
                  sponsor.push({ value: page.name, group: 'Sponsors', label: <CorporateFareOutlinedIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> })
                }
                return sponsor
              }, [])
              setOptions([...options])
              setFilteredOption([...options])
              // hint.current = hint.current + ' / '
              if (!name) {
                // show helper hint as user types
                hint.current = hint.current + options[0]?.value
              } else {
                hint.current = lockedInValue + filteredOption[currentIndex.current]?.value + ' / '
              }
            }
          }
          break
        }
        default:
          setValue(null)
          setLockedInValue('')
          break
      }
    }
  }

  // handle keyboard key operations
  const handleSelectWithKey = (event) => {
    switch (event.key) {
      case 'Tab': {
        event.preventDefault()
        // autocomplete via tab and while user press tab input field will set related values
        if (filteredOption?.length > 0) {
          currentIndex.current = (currentIndex?.current + 1) % filteredOption?.length
          const currentOption = filteredOption[currentIndex?.current]
          if (currentOption && currentOption?.value) {
            setFilterInputValue(lockedInValue + currentOption?.value)
          }
          const account = selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim() === filterInputValue?.replace(/\s+/g, ' ').trim())
          const span = selectedEntity?.results?.find((spn) => spn?.sponsorName?.replace(/\s+/g, ' ').trim() === filterInputValue?.replace(/\s+/g, ' ').trim())
          if (account || span) {
            const slashIndex = filterInputValue?.concat(' / ')?.indexOf(' / ')
            setFilterInputValue(filterInputValue?.concat(' / '))
            if (slashIndex !== -1) {
              const newLockedInValue = filterInputValue?.concat(' / ')?.substring(0, slashIndex + 3)
              setLockedInValue(newLockedInValue)
              hint.current = lockedInValue + newLockedInValue
            }
            handleFilterInputChange(filterInputValue)
            currentIndex.current = -1
          } else {
            const account = selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim().toLowerCase() === hint.current?.replace(/\s+/g, ' ').trim().toLowerCase())
            const span = selectedEntity?.results?.find((spn) => spn?.sponsorName?.replace(/\s+/g, ' ').trim().toLowerCase() === hint.current?.replace(/\s+/g, ' ').trim().toLowerCase())
            if (account || span) {
              hint.current = lockedInValue + filteredOption[currentIndex.current]?.value + ' / '
            } else {
              hint.current = lockedInValue + filteredOption[currentIndex.current]?.value
            }
          }
        }
        break
      }
      case 'Enter': {
        // hint.current = ''
        event.preventDefault()
        const account = selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim() === filterInputValue.replace(/\s+/g, ' ').trim())
        const span = selectedEntity?.results?.find((spn) => spn?.sponsorName?.replace(/\s+/g, ' ').trim() === filterInputValue.replace(/\s+/g, ' ').trim())
        if (account || span) {
          hint.current = filterInputValue + ' / '
          const slashIndex = filterInputValue?.concat(' / ')?.indexOf(' / ')
          setFilterInputValue(filterInputValue?.concat(' / '))
          if (slashIndex !== -1) {
            const newLockedInValue = filterInputValue?.concat(' / ')?.substring(0, slashIndex + 3)
            setLockedInValue(newLockedInValue)
          }
        } else {
          hint.current = ''
          const mockEvent = {
            stopPropagation: () => {},
            target: {
              innerText: isFocused
            }
          }
          handleExampleClick(mockEvent)
        }
        handleFilterInputChange(filterInputValue)
        currentIndex.current = -1
        break
      }
      case 'Backspace' : {
        if (filterInputValue.endsWith(' / ')) {
          const newInputValue = filterInputValue.substring(0, filterInputValue?.indexOf('/'))
          setValue(newInputValue)
          setFilterInputValue(newInputValue)
          setInputValue(newInputValue)
        }
        // hint.current = lockedInValue
        exampleRef.current = -1
        break
      }
      case 'Escape' : {
        clearFields()
        break
      }
      case 'ArrowUp': {
        event.preventDefault()
        if ((filteredOption?.length > 0 && inputValue) || placeHolder) {
          currentIndex.current = (currentIndex?.current - 1 + filteredOption?.length) % filteredOption?.length
          const currentOption = filteredOption[currentIndex?.current]
          if (currentOption && currentOption.value) {
            setFilterInputValue(lockedInValue + currentOption?.value)
            setValue(lockedInValue ? lockedInValue + currentOption?.value : currentOption?.value)
          }
          hint.current = lockedInValue + currentOption?.value
        } else {
          exampleRef.current = (exampleRef?.current - 1 + examples?.length) % examples?.length
          setIsFocused(examples[exampleRef.current])
        }
        break
      }

      case 'ArrowDown': {
        event.preventDefault()
        if ((filteredOption?.length > 0 && inputValue) || placeHolder) {
          currentIndex.current = (currentIndex?.current + 1) % filteredOption?.length
          const currentOption = filteredOption[currentIndex?.current]
          if (currentOption && currentOption?.value) {
            setFilterInputValue(lockedInValue + currentOption?.value)
            setValue(lockedInValue ? lockedInValue + currentOption?.value : currentOption?.value)
          }
          hint.current = lockedInValue + currentOption?.value
        } else {
          exampleRef.current = (exampleRef?.current + 1) % examples?.length
          setIsFocused(examples[exampleRef.current])
        }
        break
      }

      default:
        // setOptions([])
        // hint.current = ''
        break
    }
  }

  // when user directly selects form options via mouse click
  const handleValueChange = (event, newValue) => {
    // setValue(newValue?.value)
    if (filterInputValue.indexOf(' / ') !== -1) {
      if ((filterInputValue?.split(' / ')?.length === 2 && filterInputValue !== '') && newValue !== '') {
        const [type, _] = filterInputValue?.split(' / ')
        if (selectedEntity?.name === 'accounts') {
          const accId = selectedEntity?.results?.find((acc) => acc?.accountName?.replace(/\s+/g, ' ').trim().toLowerCase() === type?.replace(/\s+/g, ' ').trim().toLowerCase())?.accountId
          if (accId) {
            setNewValueSelected(true)
            navigate(PAGE_LIST?.find((page) => page?.name?.toLowerCase() === newValue?.value?.toLowerCase())?.path.replace(':accountId', accId))
          }
          setOpen(false)
          setBackDrop(false)
          setValue(null)
        } else {
          const spnId = selectedEntity?.results?.find((spn) => spn?.sponsorName?.replace(/\s+/g, ' ').trim().toLowerCase() === type?.replace(/\s+/g, ' ').trim().toLowerCase())?.sponsorId
          if (spnId) {
            setNewValueSelected(true)
            localStorage.setItem('ips-sponsor-id', spnId)
            dispatch(storeSelectedSponsor(spnId))
            navigate(PAGE_LIST?.find((page) => page?.name?.toLowerCase() === newValue?.value?.toLowerCase())?.path)
            setOpen(false)
            setBackDrop(false)
          }
          setValue(null)
        }
      } else if (filterInputValue !== '' && newValue !== '') {
        setValue(null)
        setNewValueSelected(true)
        setOpen(false)
        setBackDrop(false)
        const accId = selectedEntity?.results?.find((acc) => acc?.accountName === filterInputValue?.replace(' / ', ''))?.accountId
        if (accId) {
          navigate(PAGE_LIST?.find((page) => page?.name?.toLowerCase() === newValue?.value?.toLowerCase())?.path.replace(':accountId', accId))
        }
      }
    } else {
      const pageData = PAGE_LIST?.find((page) => page?.name?.toLowerCase() === newValue?.value?.toLowerCase())
      if (pageData) {
        clearFields()
        navigate(pageData?.path?.replace(':accountId', '') || pageData?.path)
      }
    }
  }

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue.includes('/') && newInputValue.split('/').length === 2 && !newInputValue.includes(' / ')) {
      if (selectedEntity?.name === 'pages') {
        return
      } else if (!newInputValue.endsWith(' /')) {
        newInputValue = newInputValue?.replace(/\//g, ' / ')
      } else {
        newInputValue = newInputValue?.replace(/\//g, '/ ')
      }
    }
    setNewValueSelected(false)
    setInputValue(newInputValue)
    setValue(newInputValue)
    setFilterInputValue(newInputValue)
    currentIndex.current = -1

    // open close options list
    if (newValueSelected) {
      setOpen(false)
      setBackDrop(false)
    } else {
      if (newInputValue?.trim()?.length) {
        setOpen(true)
        setBackDrop(true)
      }
    }

    // reset lockedValue if input is a single value
    if (newInputValue?.indexOf(' / ') === -1) {
      setLockedInValue('')
    }
  }

  const handleSearchValueSelect = (e) => {
    e.preventDefault()
    const acc = accountData?.filter(account => account?.accountName?.replace(/\s+/g, ' ').trim().toLowerCase().startsWith(e.target.innerText?.toLowerCase()))
    const spn = allowedSponsorList.filter(sponsor => sponsor?.sponsorName?.replace(/\s+/g, ' ').trim().toLowerCase().startsWith(e.target.innerText?.toLowerCase()))
    if ((acc && acc?.length > 0) || (spn && spn?.length > 0)) {
      setValue(e.target.innerText + ' / ')
    }
  }

  // handle backDrop
  const handleBackDropOpen = (e) => {
    setBackDrop(true)
    setOpen(true)
  }

  const handleBackdropClose = () => {
    setCloseOnClick(true)
  }

  // handle search for chip labels
  const handleSearchForLabelsClick = (e) => {
    e.stopPropagation()
    switch (e.target.innerText) {
      case 'Pages' : {
        const pages = PAGE_LIST?.filter(menu => menu?.roles?.includes(userGrp))?.map(menu => ({ value: menu?.name, group: 'Pages', label: <WebAssetIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} /> }))
        if (pages) {
          setOptions([...pages])
          setBackDrop(true)
          setPlaceHolder('Pages')
          setPlaceHolerMessage('Search Page')
          hint.current = ''
        } else {
          // setOptions([])
          setPlaceHolder('Pages')
          setPlaceHolerMessage('Search Page')
        }
        break
      }
      case 'Accounts' : {
        const roles = ['adv-classic', 'pm']
        const accounts = accountData?.map((acc) => ({
          value: acc?.accountName?.replace(/\s+/g, ' ').trim(),
          group: 'Accounts',
          label: <PermIdentityIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} />
        }))
        if (accounts && roles?.includes(userGrp)) {
          setOptions([...accounts])
          setBackDrop(true)
          setPlaceHolder('Accounts')
          setPlaceHolerMessage('Search Account')
          hint.current = ''
        } else {
          // setOptions([])
          setPlaceHolder('Accounts')
          setPlaceHolerMessage('Search Account')
        }
        break
      }
      case 'Sponsors' : {
        const sponsors = (allowedSponsorList || sponsorsList)?.map((page) => ({
          value: page?.sponsorName,
          group: 'Sponsors',
          label: <CorporateFareOutlinedIcon sx={{ fontSize: 'medium', color: '#71718e', margin: '1px 10px 0 6px' }} />
        }))
        if (sponsors) {
          setOptions([...sponsors])
          setBackDrop(true)
          setPlaceHolder('Sponsors')
          setPlaceHolerMessage('Search Sponsor')
          hint.current = ''
        } else {
          // setOptions([])
          setPlaceHolder('Sponsors')
          setPlaceHolerMessage('Search Sponsor')
        }
        break
      }
      default :
        setPlaceHolder('')
        setPlaceHolerMessage('')
        // setOptions([])
        // hint.current = ''
        break
    }
  }

  const handleChipDelete = () => {
    setPlaceHolder('')
    hint.current = ''
    setInputValue(filterInputValue)
    exampleRef.current = -1
    setPlaceHolerMessage('')
  }

  const handleExampleClick = (e) => {
    e.stopPropagation()
    const selectedValue = e?.target?.innerText?.type || e?.target?.innerText

    if (e.target.innerText) {
      exampleRef.current = -1
    }

    switch (selectedValue) {
      case 'Go to trades' :
      case 'Create client account' : {
        const page = PAGE_LIST?.find((page) => page?.example?.type?.toLowerCase() === selectedValue?.toLowerCase() && page?.roles.includes(userGrp))
        if (page) {
          navigate(page?.path)
        }
        break
      }
      case 'View report for user' :
      case 'View dashboard for user' : {
        const accId = accountData[0]?.accountId
        const account = PAGE_LIST?.find((acc) => acc?.example?.type?.toLowerCase() === selectedValue?.toLowerCase() && acc?.roles?.includes(userGrp) && acc?.dependentEntityKey)
        if (accId && account) {
          navigate(account?.path.replace(':accountId', accId))
        }
        break
      }
      case 'View policy' :
      case 'View monitoring':
      case 'View statement': {
        const spnId = (allowedSponsorList || sponsorsList)[0]?.sponsorId
        const sponsor = PAGE_LIST?.find((spnr) => spnr?.example?.type?.toLowerCase() === selectedValue?.toLowerCase() && spnr?.roles?.includes(userGrp) && spnr?.dependentEntityKey)
        if (sponsor && spnId) {
          localStorage.setItem('ips-sponsor-id', spnId)
          dispatch(storeSelectedSponsor(spnId))
          navigate(sponsor?.path)
        }
        break
      }
      default:
        setOpen(false)
        setBackDrop(false)
        setPlaceHolder('')
    }
  }

  const labels = ['Pages', 'Accounts', 'Sponsors']
  const icons = [WebAssetIcon, AccountBalanceOutlinedIcon, CorporateFareOutlinedIcon]

  // map labels with relative icons
  const labelIconMapping = labels.reduce((acc, label, index) => {
    acc[label] = icons[index]
    return acc
  }, {})

  const examples = PAGE_LIST?.filter((page) => page?.example && page.exampleRoles.includes(userGrp)).map(page => page.example)
  // const exampleIcons = [AnalyticsOutlinedIcon, ReceiptLongIcon, TradeIcon]
  // map examples with relative exampleIcons
  // const exampleIconMapping = examples.reduce((example, ex, index) => {
  //   example[ex] = exampleIcons[index]
  //   return example
  // }, {})

  const ListboxComponent = React.forwardRef(function ListboxComponent (rest, ref) {
    return (
      <>
        <ul style={{ maxHeight: '500px' }} ref={ref} {...rest} />
        <Box sx={{ marginLeft: '9px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Divider />
          <Stack direction='row' spacing={1} sx={{ paddingBottom: 1, marginLeft: '8px' }}>
            <Chip
              icon={<ArrowUpwardIcon sx={{ left: 7, fontSize: 'small', color: '#71718e', position: 'relative' }} />} sx={{
                borderRadius: '5px',
                color: '#41415a',
                maxHeight: '18px',
                maxWidth: '20px',
                background: '#f2f2f7'
              }}
            />
            <Chip
              icon={<ArrowDownwardIcon sx={{ left: 7, fontSize: 'small', color: '#71718e', position: 'relative' }} />} sx={{
                borderRadius: '5px',
                color: '#41415a',
                maxHeight: '18px',
                maxWidth: '20px',
                background: '#f2f2f7'
              }}
            />
            <Typography sx={{
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.4px',
              lineHeight: '20px',
              color: '#565674'
            }}
            >Navigate
            </Typography>
            <Chip
              label='Enter'
              sx={{
                borderRadius: '5px',
                color: '#41415a',
                maxHeight: '18px',
                maxWidth: '50px',
                '& .MuiChip-label': { fontSize: 10, marginTop: '2px' },
                position: 'relative',
                background: '#f2f2f7'
              }}
            />
            <Typography sx={{
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.4px',
              lineHeight: '20px',
              color: '#565674'
            }}
            >Select
            </Typography>
            {((selectedEntity?.name === 'accounts' || selectedEntity?.name === 'sponsors' || placeHolder === 'Sponsors' || placeHolder === 'Accounts') &&
             (selectedEntity?.name !== 'accounts' || placeHolder !== 'Pages')) && (
               <>
                 <Chip
                   label='/'
                   sx={{
                     borderRadius: '5px',
                     color: '#41415a',
                     maxHeight: '18px',
                     maxWidth: '50px',
                     '& .MuiChip-label': { fontSize: 10, marginTop: '2px' },
                     position: 'relative',
                     background: '#f2f2f7'
                   }}
                 />
                 <Typography sx={{
                   fontSize: '12px',
                   fontWeight: 500,
                   letterSpacing: '0.4px',
                   lineHeight: '20px',
                   color: '#565674'
                 }}
                 >Into
                 </Typography>
               </>
            )}
          </Stack>
        </Box>
      </>
    )
  })

  return (
    <Autocomplete
      disablePortal
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => {
        const [_, optionValue] = value?.split(' / ')
        return option?.value === value || option?.value === optionValue
      }}
      disableClearable
      clearOnEscape
      open={open}
      noOptionsText={`No ${noData || placeHolder || 'data'} found please search with different keyword`}
      id='controllable-states-demo'
      onChange={(event, value) => handleValueChange(event, value)}
      groupBy={(option) => option?.group}
      onInputChange={(event, newInputValue) => {
        handleInputChange(event, newInputValue)
      }}
      value={value}
      inputValue={filterInputValue || inputValue}
      getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.value}
      onKeyDown={(event) => handleSelectWithKey(event)}
      options={options || filteredOption}
      PopperComponent={(props) => {
        return (
          backDrop &&
            <Paper
              elevation={4} {...props}
              onClick={(e) => handleSearchValueSelect(e)}
              style={{
                width: 700,
                top: 40,
                borderRadius: '0px 0px 10px 10px'
              }}
              sx={{
                '& .MuiAutocomplete-noOptions': {
                  fontSize: '13px'
                },
                '& .MuiAutocomplete-groupLabel': {
                  fontSize: '12px',
                  lineHeight: '28px',
                  color: '#71718e',
                  fontWeight: 600
                },
                '& .MuiAutocomplete-option': {
                  fontSize: '13px',
                  paddingLeft: '16px !important'
                }
              }}
            >
              {(placeHolder || inputValue)
                ? (
                    props.children
                  )
                : (
                  <Box sx={{ marginTop: '20px', marginLeft: '9px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      letterSpacing: '0.4px',
                      lineHeight: '20px',
                      color: '#71718e',
                      margin: '-8px 0px 0px 9px'
                    }}
                    >
                      Searching for
                    </Typography>
                    <Stack direction='row' spacing={2} sx={{ margin: '0px 0px 5px 7px' }}>
                      {labels.map(label => {
                        const Icon = labelIconMapping[label]
                        return (
                          <Chip
                            key={label} label={label} icon={<Icon sx={{ left: 3, fontSize: 'medium', color: '#71718e' }} />} sx={{
                              borderRadius: '5px',
                              fontSize: '12px',
                              fontWeight: 500,
                              letterSpacing: '0.4px',
                              lineHeight: '20px',
                              color: '#41415a',
                              height: '25px',
                              background: '#f2f2f7'
                            }} onClick={(event) => {
                              inputRef.current.focus()
                              handleSearchForLabelsClick(event)
                            }}
                          />
                        )
                      })}
                    </Stack>
                    <Divider />
                    <Typography sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: '#71718e',
                      margin: '3px 0px 0px 9px'
                    }}
                    >
                      Examples
                    </Typography>
                    <Stack direction='column' spacing={1} sx={{ margin: '0px 10px 5px 3px' }}>
                      {examples?.map((example, index) => {
                        // const Icon = example.icon
                        const valueIndex = isFocused?.type && example?.type === isFocused?.type
                        return (
                          <Typography
                            onClick={handleExampleClick}
                            className='tradeDiv'
                            key={example?.type}
                            padding='5px 0px'
                            sx={{
                              ':hover': {
                                backgroundColor: '#1C1C1C0D',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                // '& .icon': { color: '#4d68eb' },
                                '& .arrowIcon': { visibility: 'visible', fontSize: 'large' }
                              },
                              fontSize: '15px',
                              fontWeight: 500,
                              letterSpacing: '0.3px',
                              lineHeight: '25px',
                              alignItems: 'center',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              display: 'flex',
                              color: '#41415a',
                              borderRadius: valueIndex ? '10px' : '',
                              backgroundColor: valueIndex ? '#1C1C1C0D' : ''
                            }}
                          >
                            {example?.icon === TradeIcon
                              ? (
                                <TradeIcon className='trade' width={14} height={14} style={{ marginLeft: '2px', marginRight: '8px' }} />
                                )
                              : (
                                // valueIndex to show icon colors while pressing arrow up and arrow down
                                <example.icon className='icon' sx={{ fontSize: 'medium', color: '#71718e', mr: '8px', top: '3px' }} />
                                )}
                            {example?.type}
                            <KeyboardArrowRightIcon className='arrowIcon' sx={{ color: '#71718e', right: '14px', position: 'absolute', visibility: valueIndex ? 'visible' : 'hidden', fontSize: 'large' }} />
                          </Typography>
                        )
                      })}
                    </Stack>
                    <Divider />
                    <Stack direction='row' spacing={1} sx={{ paddingBottom: 1, marginLeft: '8px' }}>
                      <Chip
                        icon={<ArrowUpwardIcon sx={{ left: 7, fontSize: 'small', color: '#71718e', position: 'relative' }} />} sx={{
                          borderRadius: '5px',
                          color: '#41415a',
                          maxHeight: '18px',
                          maxWidth: '20px',
                          background: '#f2f2f7'
                        }}
                      />
                      <Chip
                        icon={<ArrowDownwardIcon sx={{ left: 7, fontSize: 'small', color: '#71718e', position: 'relative' }} />} sx={{
                          borderRadius: '5px',
                          color: '#41415a',
                          maxHeight: '18px',
                          maxWidth: '20px',
                          background: '#f2f2f7'
                        }}
                      />
                      <Typography sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        letterSpacing: '0.4px',
                        lineHeight: '20px',
                        color: '#565674'
                      }}
                      >Navigate
                      </Typography>
                      <Chip
                        label='Enter'
                        sx={{
                          borderRadius: '5px',
                          color: '#41415a',
                          maxHeight: '18px',
                          maxWidth: '50px',
                          '& .MuiChip-label': { fontSize: 10, marginTop: '2px' },
                          position: 'relative',
                          background: '#f2f2f7'
                        }}
                      />
                      <Typography sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        letterSpacing: '0.4px',
                        lineHeight: '20px',
                        color: '#565674'
                      }}
                      >Select
                      </Typography>
                    </Stack>

                  </Box>

                  )}
            </Paper>
        )
      }}
      sx={{
        width: '300px',
        height: '35px',
        border: 'none !important',
        borderRadius: '10px !important',
        fontSize: '14px',
        '& input::placeholder': {
          color: '#727474',
          opacity: 1
        },
        '& .MuiAutocomplete-popupIndicator': {
          display: 'none'
        },
        '& .MuiInputBase-root': {
          height: '35px',
          background: '#fff',
          border: '0.5px solid rgba(116, 120, 141, 0.3)',
          borderRadius: backDrop ? '8px 8px 0px 0px' : '8px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0 !important'
        },
        '& .MuiInputBase-input': {
          py: '0 !important'
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
        {
          backgroundColor: '#0000000a'
        },
        '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
          backgroundColor: '#0000000a'
        }
      }}
      size='small'
      onFocus={() => {
        setOpen(true)
      }}
      componentsProps={{
        paper: {
          sx: {
            borderRadius: '0px 0px 10px 10px'
          }
        }
      }}
      ListboxComponent={ListboxComponent}
      renderInput={(params) =>
        <Box sx={{ position: 'relative' }}>
          <Typography sx={{ position: 'absolute', left: placeHolder ? chipWidth + 20 : 36, top: 7, fontSize: '14px', zIndex: 1600, display: 'flex', width: '380px', maxWidth: '400px' }}>
            <Box sx={{ opacity: 0.5 }}>
              {hint.current ? hint.current : ''}
            </Box>
            {hint.current && backDrop
              ? (
                <Chip
                  label='Tab'
                  sx={{
                    borderRadius: '5px',
                    color: '#41415a',
                    maxHeight: '18px',
                    background: '#f2f2f7',
                    maxWidth: '50px',
                    margin: '0px 0px 0px 7px',
                    top: '2px',
                    '& .MuiChip-label': { fontSize: 10, marginTop: '2px', right: 5, width: '30px', position: 'relative', overflow: 'unset' },
                    position: 'relative'
                  }}
                />
                )
              : (
                <></>
                )}
          </Typography>

          <TextField
            {...params} label='' inputRef={inputRef}
            placeholder={placeHolderMessage || 'Search or jump to'}
            onMouseLeave={() => {
              if (!open) {
                setOpen(false)
                setBackDrop(false)
              }
              setColor('#9d9db0')
            }}
            onFocus={() => {
              setCloseOnClick(false)
              setColor('#9d9db0')
            }}
            onClick={handleBackDropOpen}
            onMouseEnter={() => {
              if (!backDrop) {
                setColor('gray')
              }
            }}
            style={{
              // width: 700,
              // zIndex: 1500,
              // background: 'white',
              // borderBottom: backDrop ? '2px solid #002A59' : '',
              // borderRadius: backDrop ? '8px 8px 0px 0px' : '8px',
            }}
            sx={{
              width: 700,
              zIndex: 1500,
              background: 'white',
              borderBottom: backDrop ? '2px solid #002A59' : '',
              borderRadius: backDrop ? '8px 8px 0px 0px' : '8px',
              '& input': {
                fontSize: '14px'
              },
              '& input::placeholder': {
                fontSize: '15px',
                display: 'flex',
                alignItems: 'center',
                color: backDrop ? '#9d9db0' : color
              }
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start' sx={{ mx: 0, margin: '1px 0px 0px 5px' }}>
                  {
                    placeHolder
                      ? (
                        <Chip
                          ref={chipRef}
                          className='chip' label={placeHolder} onDelete={handleChipDelete}
                          deleteIcon={<ClearIcon sx={{ fontSize: '1px', padding: '1px 5px 0px 2px' }} />}
                          sx={{
                            maxHeight: '27px',
                            maxWidth: '140px',
                            borderRadius: '5px',
                            backgroundColor: '#E6ECF3',
                            fontSize: 15,
                            marginBottom: '2px',
                            '& .MuiChip-label': {
                              overflow: 'visible'
                            }
                          }}
                        />
                        )
                      : (
                        <img src={searchIcon} alt='search' style={{ height: 16, display: 'flex', color: '#4d68eb', marginBottom: '1px' }} />
                        )
                  }
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end' sx={{ mt: '1px', mb: 0, position: 'relative' }}>
                  {inputValue || backDrop
                    ? (
                      <ClearOutlinedIcon
                        onClick={() => {
                          setCloseOnClick(true)
                          setOpen(false)
                          setValue(null)
                          setInputValue('')
                          setBackDrop(false)
                          setPlaceHolder('')
                        }} sx={{
                          maxHeight: '18px',
                          display: 'flex',
                          left: '30px',
                          mr: '2px',
                          cursor: 'pointer',
                          position: 'relative'
                        }}
                      />)
                    : (
                      <>
                        <Chip
                          label='Ctrl'
                          size='small'
                          sx={{
                            borderRadius: '5px',
                            color: '#41415a',
                            maxHeight: '18px',
                            display: 'flex',
                            '& .MuiChip-label': { fontSize: 12.5, color: '#9d9db0' },
                            left: '30px',
                            mr: '2px',
                            position: 'relative',
                            background: '#f2f2f7'
                          }}
                        />
                        <Chip
                          label='K'
                          size='small'
                          sx={{
                            borderRadius: '5px',
                            color: '#41415a',
                            maxHeight: '18px',
                            display: 'flex',
                            '& .MuiChip-label': { fontSize: 12.5, color: '#9d9db0' },
                            left: '30px',
                            position: 'relative',
                            background: '#f2f2f7'
                          }}
                        />
                      </>
                      )}
                </InputAdornment>

              ),
              disableUnderline: true
            }}
          />
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} className='backdrop' onClick={handleBackdropClose} open={backDrop} />
        </Box>}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props.id}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box component='span' sx={{ wordWrap: 'break-word' }}>
                  <span style={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '25px',
                    alignItems: 'center',
                    letterSpacing: '0.3px',
                    display: 'flex',
                    marginLeft: '-8px',
                    color: '#41415a'
                  }}
                  >
                    {option.label} {option.value}
                  </span>
                </Box>
              </Grid>
            </Grid>
            {(option?.group === 'Pages' || placeHolder === 'Pages') && (
              <span style={{
                opacity: '0.4',
                fontSize: 'small',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginRight: '10px'
              }}
              >
                Jump to
              </span>
            )}
          </li>
        )
      }}
    />
  )
}

export default PageSearch
