import { useEffect, useMemo, useState } from 'react'
import { Dialog, DialogContent, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Box, IconButton, DialogTitle, Button, Typography, Tooltip, TableContainer, TableHead, Table, TableRow, TableCell, TableBody, Skeleton } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { randomId } from '@mui/x-data-grid-generator'
import { useAuth } from '../../contexts/AuthContext'
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell'

const renderTableSkeleton = (header) => {
  return (
    <TableContainer mt={5}>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((item, index) => {
              return (
                <TableCell key={index} style={{ textAlign: item.headerAlign === 'right' ? 'right' : 'left' }}>{item.headerName}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


const StrategyPortfolioUpload = (props) => {
  const { userAclData } = useAuth()
  const { dialogOpen, dialogClose, data, submitStrategyPortfolio, setConfirmModelPopup, securityLoading, isSectorAndCompanyFetching, sectorAndCompanyData, closeModal, showDynamicPortfolioHeader } = props
  const [sectorData, setSectorData] = useState([])
  const [securityData, setSecurityData] = useState(data || [])
  const [companyData, setCompanyData] = useState([])
  const [currentType, setCurrentType] = useState('security')
  const [dynamicStrategy, setDynamicStrategy] = useState(false)

  useEffect(() => {
    const strategy = userAclData?.strategyIds?.find((strategy) => strategy.resourceId === 'strategy-portfolio')
    if (strategy && strategy.dynamicStrategy && showDynamicPortfolioHeader) {
      setDynamicStrategy(true)
    }
  }, [userAclData])

  const CustomHeader = ({ title, text }) => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: '6px', textAlign: 'right' }}>
          <Tooltip title={title} placement='bottom'>
            <span style={{ lineHeight: '30px' }}>{title}</span>
          </Tooltip>
          <span style={{ lineHeight: '16px', minHeight: '20px', fontSize: '12px' }}>{text}</span>
        </Box>
      </>
    )
  }

  const handleSubmitClick = () => {
    if (securityData?.some(d => d?.instrChangeType === 'INSTR_ADDED' || d?.instrChangeType === 'INSTR_REMOVED')) {
      setConfirmModelPopup(true)
    } else {
      submitStrategyPortfolio()
    }
  }

  const modelChangedColumns = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      renderHeader: () => <CustomHeader title='Symbol' text='' />
    },
    {
      field: 'weightValue',
      headerName: 'Current Portfolio Weight(%)',
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      renderHeader: () => <CustomHeader title={dynamicStrategy ? 'Current Dynamic Portfolio Weight(%)' : 'Current Static Portfolio Weight(%)'} text='CPW' />,
      renderCell: (props) => props?.row?.currentWeight !== undefined
        ? props?.row?.currentWeight?.toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.currentWeight !== undefined
        ? props?.row?.currentWeight
        : 0.0000,
      flex: 1
    },
    {
      field: 'adjustedWeight',
      headerName: 'Uploaded Portfolio Weight(%)',
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      renderHeader: () => <CustomHeader title='Uploaded Portfolio Weight(%)' text='UPW' />,
      renderCell: (props) => props?.row?.adjustedWeight !== undefined
        ? props?.row?.adjustedWeight?.toFixed(4)
        : '0.0000',
      valueGetter: (props) => props?.row?.adjustedWeight !== undefined
        ? props?.row?.adjustedWeight
        : 0.0000,
      flex: 1
    },
    {
      field: 'drift',
      headerName: 'Weight Change(%)',
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      sortComparator: (a, b) => Math.abs(a) - Math.abs(b),
      flex: 1,
      renderHeader: () => <CustomHeader title='Weight Change(%)' text='WC = UPW - CPW' />,
      renderCell: (params) => {
        const driftVal = params?.row?.drift
        const invalid = Math.abs(parseFloat(driftVal).toFixed(4)) === 0
        return (
          <>
            {driftVal < 0 && (params?.row?.instrChangeType !== 'INSTR_REMOVED' && params?.row?.instrChangeType !== 'INSTR_ADDED') && !invalid
              ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
              : (driftVal > 0 && (params?.row?.instrChangeType !== 'INSTR_REMOVED' && params?.row?.instrChangeType !== 'INSTR_ADDED')) && !invalid
                  ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
                  : params?.row?.instrChangeType === 'INSTR_REMOVED' && !invalid
                    ? <RemoveCircleOutlineIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
                    : params?.row?.instrChangeType === 'INSTR_ADDED' && !invalid
                      ? <AddCircleOutlineIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
                      : ''}
            <Typography
              fontSize='14px'
              fontWeight='600'
              color={parseFloat(driftVal).toFixed(4) < 0 && !invalid
                ? '#FF6161'
                : (parseFloat(driftVal).toFixed(4) > 0 && !invalid)
                    ? '#3BBFA3'
                    : ''}
              sx={{ width: '55px', textAlign: 'right', display: 'inline-block' }}
            >
              {Math.abs(parseFloat(driftVal).toFixed(4)) === 0 ? '0.0000' : parseFloat(driftVal).toFixed(4)}
            </Typography>
          </>
        )
      },
      valueGetter: (props) => props?.row?.drift !== undefined && props?.row?.drift !== null
        ? Math.abs(parseFloat(props?.row?.drift).toFixed(4)) === 0 ? 0.0000 : props?.row?.drift
        : 0.0000
    }

  ]

  if (currentType === 'security') {
    modelChangedColumns.unshift({
      field: 'name',
      headerName: 'Instrument Name',
      flex: 1,
      renderHeader: () => <CustomHeader title='Instrument Name' text='' />
    })
  }

  const groupRows = (rows, groupBy) => {
    return rows.reduce((acc, item) => {
      const key = item[groupBy]
      if (!acc[key]) {
        acc[key] = {
          id: key,
          [groupBy]: key,
          weight: 0,
          adjustedWeight: 0,
          currentWeight: 0,
          drift: 0,
          isLeaf: false,
          symbol: groupBy === 'instrId' ? item.symbol : '',
          instrChangeType: item.instrChangeType,
          name: item?.name,
          children: []
        }
      }
      acc[key].weight += item.weight
      acc[key].adjustedWeight += item.adjustedWeight
      acc[key].currentWeight += item.currentWeight
      acc[key].drift += item.drift
      if (acc[key].instrChangeType !== item.instrChangeType) {
        acc[key].instrChangeType = 'INSTR_ALTERED'
      }
      acc[key].children.push({ ...item, id: `${key}-${item.instrId}-${randomId()}`, isLeaf: true })
      return acc
    }, {})
  }

  useEffect(() => {
    if (data && data?.length) {
      setSecurityData(data)
    }
  }, [data])

  useEffect(() => {
    if (sectorAndCompanyData && sectorAndCompanyData?.length) {
      setSectorData(groupRows(sectorAndCompanyData, 'sector'))
      setCompanyData(groupRows(sectorAndCompanyData, 'company'))
    }
  }, [sectorAndCompanyData])

  const getGroupingColDef = (value) => {
    switch (value) {
      case 'sector':
        return {
          headerName: 'Sector Name',
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} groupType={value} />,
          flex: 1,
          renderHeader: () => <CustomHeader title='Sector Name' text='' />
        }
      case 'company':
        return {
          headerName: 'Company Name',
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} groupType={value} />,
          flex: 1,
          renderHeader: () => <CustomHeader title='Company Name' text='' />
        }
      default:
    }
  }

  // grouping for leaf(chields) nodes if it has duplicated instrId entries
  const childGrouping = (parentData) => {
    const groupedChildren = Object.values(parentData).reduce((acc, item) => {
      acc.push(item)
      if (item.children) {
        item.children.forEach(child => {
          if (!acc.find(el => el.instrId === child.instrId)) {
            acc.push({
              ...child,
              weight: child.weight,
              adjustedWeight: child.adjustedWeight,
              currentWeight: child.currentWeight,
              drift: child.drift
            })
          } else {
            const existingChild = acc.find(el => el.instrId === child.instrId)
            existingChild.weight += child.weight
            existingChild.adjustedWeight += child.adjustedWeight
            existingChild.currentWeight += child.currentWeight
            existingChild.drift += child.drift
          }
        })
      }
      return acc
    }, [])
    return groupedChildren
  }

  const sectorRow = useMemo(() => { return Object?.values(sectorData)?.flatMap(item => [item, ...item.children]) }, [sectorData])

  const companyRow = useMemo(() => {
    return childGrouping(companyData)
  }, [companyData])

  const renderSelectedValueTable = () => {
    switch (currentType) {
      case 'security' : {
        return (
          <DataGridPro
            density='compact'
            autoHeight
            rows={securityData}
            getTreeDataPath={(row) => ([row?.id])}
            columns={modelChangedColumns}
            getRowId={(row) => row?.id}
            rowSelection={false}
            pagination
            pageSizeOptions={[15]}
            initialState={{
              ...securityData?.initialState,
              sorting: {
                sortModel: [
                  {
                    field: 'drift',
                    sort: 'desc'
                  }
                ]
              },
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.footerContainer}`]: {
                position: 'sticky',
                bottom: '-1px',
                backgroundColor: theme.palette.background.paper,
                zIndex: 1
              }
            })}
          />
        )
      }
      case 'sector': {
        if (isSectorAndCompanyFetching) {
          return <>{renderTableSkeleton([getGroupingColDef(currentType),...modelChangedColumns])}</>
        }
        return (
          <DataGridPro
            density='compact'
            autoHeight
            treeData
            getTreeDataPath={(row) => (row.children ? [row.sector] : [row.sector, row.id])}
            groupingColDef={getGroupingColDef(currentType)}
            rows={sectorRow}
            columns={modelChangedColumns}
            getRowId={(row) => row?.id}
            disableSelectionOnClick
            disableRowSelectionOnClick
            pagination
            pageSizeOptions={[15]}
            initialState={{
              ...sectorRow,
              sorting: {
                sortModel: [
                  {
                    field: 'drift',
                    sort: 'desc'
                  }
                ]
              },
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.footerContainer}`]: {
                position: 'sticky',
                bottom: '-1px',
                backgroundColor: theme.palette.background.paper,
                zIndex: 1
              }
            })}
          />
        )
      }
      case 'company': {
        if (isSectorAndCompanyFetching) {
          return <>{renderTableSkeleton([getGroupingColDef(currentType), ...modelChangedColumns])}</>
        }
        return (
          <DataGridPro
            density='compact'
            autoHeight
            treeData
            getTreeDataPath={(row) => (row.children ? [row.company] : [row.company, row.id])}
            groupingColDef={getGroupingColDef(currentType)}
            rows={companyRow}
            columns={modelChangedColumns}
            getRowId={(row) => row?.id}
            disableSelectionOnClick
            disableRowSelectionOnClick
            pagination
            pageSizeOptions={[15]}
            initialState={{
              ...companyRow,
              sorting: {
                sortModel: [
                  {
                    field: 'drift',
                    sort: 'desc'
                  }
                ]
              },
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            sx={(theme) => ({
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                backgroundColor: theme.palette.background.paper,
                top: 0,
                zIndex: 1
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.footerContainer}`]: {
                position: 'sticky',
                bottom: '-1px',
                backgroundColor: theme.palette.background.paper,
                zIndex: 1
              }
            })}
          />
        )
      }
      default :
    }
  }

  return (
    <>
      <Dialog fullScreen open={dialogOpen}>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Step 2 of 2: Model Changes</Typography>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', color: '#34475A' }}>
                <FormLabel id='position-controlled-radio-buttons-group' />
                <RadioGroup row name='position-radio-group' value={currentType} onChange={(e) => setCurrentType(e.target.value)}>
                  <FormControlLabel value='security' control={<Radio size='small' />} label='Security' />
                  <FormControlLabel value='sector' control={<Radio size='small' />} label='Sector' />
                  <FormControlLabel value='company' control={<Radio size='small' />} label='Company' />
                </RadioGroup>
              </FormControl>
              <IconButton
                onClick={() => closeModal()}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ position: 'absolute', bottom: 24, alignItems: 'center', display: 'flex', width: '100%', justifyContent: 'space-between', pl: 5 }}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ display: 'flex', mx: 1 }}>
                    <AddCircleOutlineIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '6px' }} />
                    <Typography sx={{ color: '#74788d', fontSize: '14px' }}> Security Added</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', mx: 1 }}>
                    <RemoveCircleOutlineIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '6px' }} />
                    <Typography sx={{ color: '#74788d', fontSize: '14px' }}> Security Removed</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', mx: 1 }}>
                    <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '6px' }} />
                    <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Weight Increased </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', mx: 1 }}>
                    <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '6px' }} />
                    <Typography sx={{ color: '#74788d', fontSize: '14px' }}>Security Weight Decreased </Typography>
                  </Box>
                </Box>
                {securityLoading
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      sx={{ zIndex: 99 }}
                      variant='contained'
                      color='primary'
                    >
                      Submit
                    </LoadingButton>
                    )
                  : (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        variant='text'
                        onClick={() => dialogClose(false)}
                        sx={{ zIndex: 99, minWidth: '90px' }}
                      >
                        Back
                      </Button>
                      <Button
                        variant='contained'
                        sx={{ zIndex: 99, minWidth: '90px' }}
                        onClick={handleSubmitClick}
                      >
                        Submit
                      </Button>
                    </Box>
                    )}
              </Box>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            className='table-responsive'
            sx={{
              height: 'calc(100vh - 72px - 24px - 48px)',
              width: '100%',
              position: 'relative',
              overflowY: 'auto',
              scrollbarWidth: 'none'
            }}
          >
            {renderSelectedValueTable()}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default StrategyPortfolioUpload
