import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Skeleton, Typography } from '@mui/material'

const AllocationChart = ({ groupHierarchy, loading }) => {
  const [selectedAssetLevel, setSelectedAssetLevel] = useState(1)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const finalArray = []
    hierarchyToArray(groupHierarchy, finalArray, 0)
    setChartData(finalArray)
  }, [groupHierarchy])

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    colors: ['#002A59', '#3369A6', '#3B79BF', '#6A86A6', '#6E9FC0', '#95CEEC', '#BCBEBE', '#9DDFD0', '#93DAC0', '#AEC7E2', '#B3D1F2', '#CFE5FF', '#E0E8EF', '#DBE9F9', '#C6F7FA'],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      }
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        show: true,
        formatter: (data) => data.toFixed(2) + '%',
      },
    },
    grid: {
      show: false,
    },
  }

  /*
    hierarchyToArray: converts hierarchy structure into array
    input:
      hierarchy: object which represent level name as key and value as child hierarchy object
      finalArray: array to store converted array value from object
      index: identifies current level or depth of recursion at the function is running currently
    output:
      returns summation of required fields at the current level to add in parent key array
  */

  function hierarchyToArray(hierarchy, finalArray, index) {
    const fieldsRequiredForSummation = { weight: 0 }
    // at the last level, type of hierarchy is array of object
    if (Array.isArray(hierarchy)) {
      // in allocation chart only account allocation value is required
      hierarchy.forEach((object) => {
        fieldsRequiredForSummation.weight += object?.weight
      })
      return fieldsRequiredForSummation
    }
    for (const key in hierarchy) {
      // using different array because of child and parent positioning
      const arrayFromCurrentLevel = []
      const aggregatedFieldsFromChild = hierarchyToArray(hierarchy[key], arrayFromCurrentLevel, index + 1)
      // only want the data of selected asset level and it can't be null
      // untagged data is not related to any level so it can be shown on any level
      if ((key !== 'null' && index === selectedAssetLevel - 1) || key === 'Untagged') {
        // every key is parent of value, so key should be added at start of array
        arrayFromCurrentLevel.unshift({ ...aggregatedFieldsFromChild, key })
      }
      // add the generated child hierarchy array into final array
      arrayFromCurrentLevel.forEach(obj => {
        finalArray.push(obj)
      })
      // add up the required fields properties from sibling to show in parent
      for (const key in fieldsRequiredForSummation) {
        fieldsRequiredForSummation[key] += aggregatedFieldsFromChild[key]
      }
    }
    return fieldsRequiredForSummation
  }

  const series = []
  chartData?.map(data => {
    if (data?.key !== null) {
      const index = series.findIndex(x => x.name === data?.key)
      if (index === -1) {
        series.push({ name: data?.key, data: [Number(data?.weight !== null ? data?.weight : 0)] })
      }
      else {
        series[index].data[0] += Number(data?.weight !== null ? data?.weight : 0)
      }
    }
  })

  series.sort((a, b) => b.data[0] - a.data[0])

  return (
    <>
      <Typography component='h3' className='text-title'>Asset Allocation</Typography>
      {
        loading
          ? <Skeleton variant='rectangular' sx={{ height: '40px', mt: '10px' }} />
          : <Chart options={options} series={series} type='bar' height={60} />
      }
    </>
  )
}

export default AllocationChart