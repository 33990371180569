import { combineReducers, configureStore } from '@reduxjs/toolkit'
import dashboardReducer from './dashboard-reducer/dashboard-reducer'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistCombineReducers
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from './user-reducer/user-reducer'
import tradeReducer from './trade-reducer/trade-reducer'
import accountReviewReducer from './account-review-reducer/account-review-reducer'
import templateReducer from './pdf-customizer-reducer/Reducer/template-reducer.js'
import elementStates from './pdf-customizer-reducer/Reducer/elementStates.js'
import allPageStyle from './pdf-customizer-reducer/Reducer/allPageStyle'
import uiReducer from './pdf-customizer-reducer/Reducer/uiReducer'
import chartState from './pdf-customizer-reducer/Reducer/chartState.js'
import { thunk } from 'redux-thunk'
import customSponsorDesignReducer from './custom-sponsor-design-reducer/custom-sponsor-design-reducer'

const persistConfig = {
  key: 'root', // This is the storage key. Change it if needed.
  storage, // Use the storage engine (local storage by default),
  whitelist: ['user', 'trade', 'theme']
}

// Create root reducer
const rootReducer = persistCombineReducers(persistConfig, {
  dashboard: dashboardReducer,
  user: userReducer,
  trade: tradeReducer,
  accountReview: accountReviewReducer,
  theme: customSponsorDesignReducer,
  templateData: templateReducer,
  uiReducer,
  elementStates,
  allPageStyle,
  chartState
})

// Create persistReducer from root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(thunk)
  // // middleware: (getDefaultMiddleware) =>
  // //   getDefaultMiddleware({
  // //     serializableCheck: {
  // //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  // //     }
  // //   })
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
      actionCreatorCheck: true
    }).concat(thunk)
})

// Create persist store
const persistor = persistStore(store)

export { store, persistor }
